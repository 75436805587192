.hotel {
  cursor: pointer;
  display: flex;
  width: 100%;
  border: 1px solid transparent;
  box-shadow: var(--boxShadow);
  transition: all 0.3s;
  &:hover {
    border-color: var(--black);
    transition: all 0.3s;
  }
  .left {
    flex-shrink: 0;
    width: 200px;
    min-height: 200px;
    padding: 12px;
    border-right: 1px solid var(--black);
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    @media only screen and (max-width: 767.98px) {
      display: none;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .rightTop {
      padding: 12px 12px 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 8px 0;
      .rightTopLeft {
        width: 50%;
        .title {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        @media only screen and (max-width: 991.98px) {
          width: 100%;
        }
      }
      .rightTopRight {
        display: flex;
        align-items: center;
        gap: 16px;
        .divider {
          width: 1px;
          height: 24px;
          background-color: var(--black);
        }
        .price {
          font-size: 24px;
          font-weight: 600;
          text-align: right;
          .saving {
            color: var(--green);
            text-align: right;
            font-size: 14px;
            font-weight: 400;
          }
        }
        .priceUnavailable {
          font-size: 20px;
          font-weight: 600;
        }
        .rating {
          width: fit-content;
          color: var(--white);
          background-color: var(--secondary);
          padding: 6px;
          font-size: 18px;
          font-weight: 600;
        }
        .bookButton {
          border-radius: 0;
          box-shadow: none;
          text-transform: capitalize;
          background-color: var(--primary);
          transition: all 0.3s;
          &:hover {
            filter: brightness(1.25);
            transition: all 0.3s;
          }
        }
      }
    }

    .rightMiddle {
      padding: 12px;
      .address {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        margin-bottom: 4px;
        > svg {
          flex-shrink: 0;
        }
        > p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      .description {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        > svg {
          flex-shrink: 0;
        }
        > div {
          height: 19px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }

    .rightBottom {
      border-top: 1px solid var(--black);
      overflow-x: auto;
      padding: 6px 12px;
      .competitorList {
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 36px;
        .competitor {
          > p {
            font-size: 14px;
            line-height: 100%;
          }
          .competitorName {
            font-weight: 600;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}
