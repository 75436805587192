.hotelModal {
  [class~='MuiPaper-root'] {
    width: 100%;
    max-width: 750px;
    padding: 24px;
  }
  .closeIcon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-color: var(--white);
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .image {
    width: 100%;
    max-width: 250px;
    height: auto;
    margin-bottom: 16px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .name {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .highlight {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin: 12px 0 24px;
    .divider {
      width: 1px;
      height: 24px;
      background-color: var(--black);
    }
    .price {
      font-size: 24px;
      font-weight: 600;
      text-align: right;
      .saving {
        color: var(--green);
        text-align: right;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .priceUnavailable {
      font-size: 20px;
      font-weight: 600;
    }
    .rating {
      width: fit-content;
      color: var(--white);
      background-color: var(--secondary);
      padding: 6px;
      font-size: 18px;
      font-weight: 600;
    }
    .bookButton {
      border-radius: 0;
      box-shadow: none;
      text-transform: capitalize;
      background-color: var(--primary);
      transition: all 0.3s;
      &:hover {
        filter: brightness(1.25);
        transition: all 0.3s;
      }
    }
  }
  .hr {
    border: 0.5px solid var(--black);
  }
  .info {
    margin: 24px 0;
    &.noCompetitors {
      margin-bottom: 0;
    }
    .address {
      display: flex;
      align-items: flex-end;
      gap: 8px;
      margin-bottom: 12px;
      > svg {
        flex-shrink: 0;
      }
    }
    .description {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      > svg {
        flex-shrink: 0;
      }
    }
  }
  .competitorList {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px 36px;
    .competitor {
      > p {
        font-size: 14px;
        line-height: 100%;
      }
      .competitorName {
        font-weight: 600;
        margin-bottom: 4px;
      }
    }
  }
}
