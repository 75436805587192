.header {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  color: var(--white);
  background-color: var(--primary);
  .container {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .currencySelect {
      [class~='MuiFormLabel-root'],
      [class~='MuiInputBase-root'] {
        color: var(--white);
      }
      [class~='MuiSvgIcon-root'] {
        > path {
          fill: var(--white);
        }
      }
      [class~='MuiInputBase-root'] {
        transition: all 0.3s;
        &:hover {
          background-color: var(--active);
          transition: all 0.3s;
        }
      }
      [class~='MuiOutlinedInput-notchedOutline'] {
        border-width: 1px;
        border-color: var(--white);
      }
      [class~='MuiSelect-select'] {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.menuItem {
  gap: 12px;
  > span {
    line-height: 100%;
  }
}
